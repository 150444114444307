
import action from "@/actions.js"
import qs from 'qs'
export default {
    data(){
        return {
            upload_url:action.uploadpic,
            agentlist:[],
            dialogVisible:false,
            dialogImageUrl:'',
            curimglist:'',
            LegalPersonFileList:[],
            CertificationFileList:[],
            AuthorizationFileList:[],
            storeinfo:{
                ID:'',
                StoreName:'',
                StoreCode:'',
                Mobile:'',
                Address:'',
                AgentID:'',
                PlatformID:'',
                ChannelID:'',
                AuthType:'',
                fkProvince:'',
                fkCity:'',
                fkArea:"",
                Deposit:0,
                Discount:0,
                LegalPersonIDs:[],
                Certification:[],
                Authorization:[],
                StartDTime:'',
                ExpireDTime:'',
                ManagerID:'',
                Target:'',
            },
            rule:{
				StoreName:[{
					required:'true',
					message:"请填写商户名称",
					trigger:'blur'
				}],
				ManagerID:[{
					required:'true',
					message:"请选择客户代表",
					trigger:'blur'
				}],
                fkProvince:[{
                    required:'true',
					message:"请选择省份",
					trigger:'blur'
                }],
                fkCity:[{
                    required:'true',
					message:"请选择城市",
					trigger:'blur'
                }],
                fkArea:[{
                    required:'true',
					message:"请选择县区",
					trigger:'blur'
                }],
                PlatformID:[{
                    required:'true',
					message:"请选择经营平台",
					trigger:'blur'
                }],
                ChannelID:[{
                    required:'true',
					message:"请选择经营渠道",
					trigger:'blur'
                }],
                AuthType:[{
                    required:'true',
					message:"请选择授权类型",
					trigger:'blur'
                }]
			},
            province:[],
            city:[],
            area:[],
        }
    },
    beforeCreate() {
		this.PAGELANG = this.LANG.pages.AddStore;
	},
    methods:{
        onSubmit(){
            console.log(this.storeinfo);
            this.$refs['storeinfo'].validate((valid)=>{
                if(valid){
                    this.axios.post(action.savestore,qs.stringify(this.storeinfo),{
                        headers:{
                            "content-type":"application/x-www-form-urlencoded"
                        }
                    }).then((response)=>{
                        console.log(response.data);
                        if(response.data == 'OK'){
                            this.$message.success("操作成功");
                        }else if(response.data == 'REPEAT'){
                            this.$message.error("已存在相同的店铺");
                        }else
                            this.$message.error("操作出错");
                    });
                }
            });
        },
        Setuploadlist(name){
            this.curimglist = name;
        },
        GoBack(){
            this.$router.push({path:'/customers/storelist',query:{curpage:this.curpage}});
        },
        UploadExceed(file,filelist){
            this.$message.warning(`当前限制选择 10 个图片,本次选择了 ${file.length} 个文件,共选择了 ${file.length + filelist.length} 个文件`);
        },
        UploadSuccess(response,file,filelist){
            console.log(response);
            if(response.msg == 'OK'){
				if(this.curimglist == 'legalperson')
					this.storeinfo.LegalPersonIDs = [];
				else if(this.curimglist == 'certification')
					this.storeinfo.Certification = [];
				else if(this.curimglist == 'authorization')
					this.storeinfo.Authorization = [];
                for(var i = 0;i<filelist.length;i++){
                    var uid = filelist[i]['uid'];
                    var name = filelist[i]['name'];
                    var url = '';
                    if(filelist[i]['response']){
                        var upload_response = filelist[i]['response'];
                        url = upload_response.url;
                    }else{
                        url = filelist[i]['url'];
                    }
                    var obj = new Object();
                    obj.uid = uid;
                    obj.name = name;
                    obj.url = url;
					console.log("curimglist--" + this.curimglist);
					if(this.curimglist == 'legalperson'){
						this.storeinfo.LegalPersonIDs.push(obj);
						console.log("legalperson");
						console.log(this.storeinfo.LegalPersonIDs);
					}
					else if(this.curimglist == 'certification'){
						this.storeinfo.Certification.push(obj);
						console.log(this.storeinfo.Certification);
					}
					else if(this.curimglist == 'authorization'){
						this.storeinfo.Authorization.push(obj);
						console.log(this.storeinfo.Authorization);
					}
                }
            }
            
        },
        RemovePic(file,filelist){
            console.log(file);
            console.log(filelist);
			if(this.curimglist == 'legalperson')
				this.storeinfo.LegalPersonIDs = [];
			else if(this.curimglist == 'certification')
				this.storeinfo.Certification = [];
			else if(this.curimglist == 'authorization')
				this.storeinfo.Authorization = [];
			
            for(var i = 0;i<filelist.length;i++){
                var uid = filelist[i]['uid'];
                var name = filelist[i]['name'];
                var url = filelist[i]['url'];
                var obj = new Object();
                obj.uid = uid;
                obj.name = name;
                obj.url = url;
                if(this.curimglist == 'legalperson')
                    this.storeinfo.LegalPersonIDs.push(obj);
				else if(this.curimglist == 'certification')
					this.storeinfo.Certification.push(obj);
				else if(this.curimglist == 'authorization')
					this.storeinfo.Authorization.push(obj);
				
            }
        },
        CheckFile(file){
			
            console.log(file);
            if(file.type != "image/jpg" && file.type !="image/png" && file.type != "image/jpeg"){
                this.$message.warning('当前选择文件中包含不支持上传的类型');
                return false;
            }
            if((file.size*1)/1024 > 2048){
				this.$message.error("当前上传图片尺寸过大,建议图片大小在2MB内");
				return false;
			}
        },
        UploadError(err){
            console.log(err);
            this.$message.error(err);
        },
        Preview(file) {
            console.log(file);
            if(file.response){
                if(file.response.msg == 'OK'){
                    this.dialogImageUrl = file.response.url;
                    console.log(this.dialogImageUrl);
                }
            }
            else{
                this.dialogImageUrl = file.url;
            }
            
            this.dialogVisible = true;
        },
        GetCity(province){
            console.log(province);
            this.axios.get(action.getcity,{
                headers:{
                    "content-type":"application/json"
                },
                params:{
                    province:province
                }
            }).then((response)=>{
                console.log(response.data);
                this.city = response.data;
                
            });
        },
        GetArea(city){
            console.log(city);
            this.axios.get(action.getarea,{
                headers:{
                    "content-type":"application/json"
                },
                params:{
                    city:city
                }
            }).then((response)=>{
                console.log(response.data);
                this.area = response.data;
            });
        }
    },
    created:function(){
        let id = this.$route.query.id;
		let curpage = this.$route.query.curpage;
        if(curpage)
            this.curpage = curpage;
        this.axios.get(action.getagentlist,{
            headers:{
                "content-type":"application/json"
            }
        }).then((response)=>{
            console.log(response.data);
            this.agentlist = response.data;
        });

        this.axios.get(action.getprovince,{
            headers:{
                "content-type":"application/json"
            },
        }).then((response)=>{
            console.log(response.data);
            this.province = response.data;
        });

        if(id){
            this.axios.get(action.getstore,{
                headers:{
                    "content-type":"application/json"
                },
                params:{
                    id:id
                }
            }).then((response)=>{
                console.log(response.data);
                this.storeinfo = response.data;
                if(response.data.LegalPersonIDs)
                    this.LegalPersonFileList = response.data.LegalPersonIDs;
                if(response.data.Certification)
                    this.CertificationFileList = response.data.Certification;
                if(response.data.Authorization)
                    this.AuthorizationFileList = response.data.Authorization;
                    
                    //if(response.data.fkCity)
                        //this.GetArea(response.data.fkCity);
                    
                    //this.storeinfo.StartDTime = response.data.StartDTime*1000;
                    //this.storeinfo.ExpireDTime = response.data.ExpireDTime*1000;
            });
        }
        
        
    }
}
